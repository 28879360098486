<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchForm.keyword"
            placeholder="问题关键字"
            clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-select v-model="searchForm.statu" placeholder="请选择状态">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getNoticeList">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        stripe>

      <el-table-column
          prop="content"
          label="问题名称">
      </el-table-column>
      <el-table-column
          prop="user"
          label="反馈用户" width="130">
      </el-table-column>
      <el-table-column
          prop="created"
          label="反馈时间" width="230">
      </el-table-column>
      <el-table-column
          prop="statu"
          label="状态" width="100">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.statu === 1" type="success">已处理</el-tag>
          <el-tag size="small" v-else-if="scope.row.statu === 0" type="danger">未处理</el-tag>
        </template>

      </el-table-column>
      <el-table-column
          prop="icon"
          label="操作"
          width="100">

        <template slot-scope="scope">

          <el-button type="text" @click="editHandle(scope.row.id)">详情</el-button>

        </template>
      </el-table-column>

    </el-table>


    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>
    <!--详情对话框-->
    <el-dialog
        title="反馈详情"
        :visible.sync="dialogVisible"
        width="1300px">

      <el-form :model="editForm" ref="editForm" label-width="100px" class="demo-editForm">

        <el-form-item label="反馈内容" prop="question" label-width="100px">
          <el-input type="textarea"
                    :autosize="{ minRows: 2, maxRows: 10}"
                    v-model="editForm.content" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="截图证明" prop="category" label-width="100px">
          <el-image :src="item" v-for="item in editForm.imgs" style="width: 300px;margin: 5px;"></el-image>
        </el-form-item>

        <el-form-item v-if="editForm.statu == 0">
          <el-button type="primary" @click="editstatu(editForm.id)">处理</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "notice",
  data() {
    return {
      tableData: [],
      searchForm: {
        statu: '0'
      },
      options: [{
        value: '0',
        label: '未处理'
      }, {
        value: '1',
        label: '已处理'
      }],

      total: 0,
      size: 10,
      current: 1,
      dialogVisible: false,
      editForm: {}
    }
  },
  created() {
    this.getNoticeList()

    this.$axios.get('/interview/category/list').then(res => {
      this.permTreeData = res.data.data
    })
  },
  methods: {

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getNoticeList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getNoticeList()
    },

    getNoticeList() {
      this.$axios.get("/interview/notice/list", {
        params: {
          keyword: this.searchForm.keyword,
          statu: this.searchForm.statu,
          current: this.current,
          size: this.size
        }
      }).then(res => {
        this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total
      })
    },
    editHandle(id) {
      this.$axios.get('/interview/notice/info/' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    editstatu(id) {
      this.$axios.get("/interview/notice/deal/"+id).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，处理成功',
          type: 'success',
          onClose: () => {
            this.getNoticeList()
          }
        });
        this.dialogVisible = false
      })
    },
  }
}
</script>

<style scoped>

</style>
